<script setup lang="ts">
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";
import AppFormFieldLabel from "@/Components/Shared/forms/AppFormFieldLabel.vue";
import AppFormField from "@/Components/Shared/forms/AppFormField.vue";
import { defaultFormEmits, defaultInputProps } from "@/Utils/form";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppText from "@/Components/Shared/text/AppText.vue";
import { computed, ComputedRef, ref } from "vue";
import AppFormInputText from "@/Components/Shared/forms/AppFormInputText.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";

type InputType = "text" | "password";
type Props = {
    modelValue?: string;
} & defaultInputProps;
const { modelValue = undefined } = defineProps<Props>();

const emit = defineEmits(defaultFormEmits);

// classes
const classes: AntlerClasses<Props> = {
    base: "",
    variants: {},
};
const { aClass } = installAntlerComponent("input-password", {}, classes);

// password
const displayPassword = ref(false);
const inputType: ComputedRef<InputType> = computed(() => {
    if (displayPassword.value) {
        return "text";
    }

    return "password";
});

// other
const handleInput = (event: any) => {
    emit("update:modelValue", event.target.value);
};
const handleAnimation = (event: any) => {
    if (event.animationName == "onAutoFillStart") {
        emit("update:modelValue", event.target.value);
    }
};
</script>

<template>
    <AppFormInputText
        :class="aClass()"
        :type="inputType"
        :label="label"
        :model-value="modelValue"
        :name="name"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :hint="hint"
        :errors="errors"
        @input="$emit('update:modelValue', $event.target.value)"
        @animationstart="handleAnimation"
    >
        <slot />

        <template #action>
            <AppButton
                :key="`password-${displayPassword}`"
                class="absolute top-1/2 right-1 -translate-y-1/2"
                variant="ghost"
                :icon="displayPassword ? 'eye-closed' : 'eye'"
                icon-size="medium"
                type="button"
                @click.prevent="displayPassword = !displayPassword"
            />
        </template>
    </AppFormInputText>
</template>
